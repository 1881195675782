import cn from 'classnames';
import dayjs from 'dayjs';
import {cardCn} from '@rescui/card';
import Button from '@rescui/button';
import {useTextStyles} from '@rescui/typography';

import styles from './card.module.css';
import React, {FC} from 'react';
import {Promotion} from '../../pages/feed/data';
import {useNavigate} from 'react-router';
import {Pets, Plants} from '../../pages/offer-create/enums';
import {useGlowHover} from '@rescui/use-glow-hover';
import {PromotionTypeTag} from '../promotion-type-tag';
import {UserPic} from '../../pages/offer-view/offer-cart';
import {S3Image} from "../image-downloader";

const CARE_OF = {
  pets: '🐾',
  plants: '🌿',
};

export const Card: FC<{
  promotion: Promotion;
}> = ({
  promotion: {
    promotion_type,
    id,
    additional_info,
    location_address: { city, images, address, postcode },
    from_date,
    till_date,
    user_data: {
      display_name,
      additional_info: { avatar_link },
    },
  },
}) => {
  const refCard = useGlowHover({ lightColor: '#9C8FFF', lightSize: 150 });
  const navigate = useNavigate();
  const cardClassName = cardCn({ isClickable: true, borderRadius: 16, paddings: 32, hasGlowHover: true });
  const textCn = useTextStyles();
  const careList = [];
  if (additional_info) {
    const pets = Object.values(Pets);
    const plats = Object.values(Plants);
    if (hasTag(additional_info, pets)) {
      careList.push(CARE_OF.pets);
    }
    if (hasTag(additional_info, plats)) {
      careList.push(CARE_OF.plants);
    }
  }

  return (
    <div
      onClick={() => {
        navigate(`/offer/${id}`);
      }}
      ref={refCard as any}
      className={cn(styles.card, cardClassName)}
    >
      <div className={styles.cardWrapper}>
        <div className={cn(styles.header, styles.cardContent)}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UserPic link={avatar_link}></UserPic>
            <div className={cn(styles.user, textCn('rs-text-3', { hardness: 'hard' }))}>{display_name}</div>
          </div>
          <PromotionTypeTag type={promotion_type} />
        </div>
        {images && images[0] && images[0].image_url && (
          <div className={styles.imageWrapper}>
            <div className={cn(styles.care, textCn('rs-text-3'))}>
              <ul className={styles.list}>
                {careList.length === 0 ? (
                  <li style={{ height: '24px' }} />
                ) : (
                  careList.map((item) => (
                    <li key={item} className={styles.pets}>
                      {/*@ts-ignore*/}
                      {item}
                    </li>
                  ))
                )}
              </ul>
            </div>
            <S3Image
              className={styles.image}
              objectKey={images[0].image_url}
            />
          </div>
        )}
        <div className={styles.cityDate}>
          <div className={textCn('rs-h3')}>
            {city} {from_date !== null && '🔥'}
          </div>
          <div className={cn(styles.digits, textCn('rs-digits-2'))}>
            {from_date ? (
              <>
                {dayjs(from_date).format('D.M')}—{till_date === null ? '...' : dayjs(till_date).format('D.M')}
              </>
            ) : (
              <>
                <br />
              </>
            )}
          </div>
        </div>
        <div className={cn(styles.address, textCn('rs-text-3', { hardness: 'hard' }))}>
          {[postcode, address].filter(Boolean).join(', ')}
        </div>
      </div>
      <div className={textCn('rs-text-2')}>
        <Button
          className={styles.button}
          mode={'rock'}
          onClick={() => {
            navigate(`/offer/${id}`);
          }}
        >
          View now
        </Button>
      </div>
    </div>
  );
};

function hasTag(tags: string[], careOf: string[]) {
  return tags.filter((t) => careOf.includes(t)).length > 0;
}
