import React from 'react';
import { Outlet } from 'react-router';
import Header from './header';
import Footer from './footer';

import styles from './index.module.css';

const Layout = () => {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <Header />
        <main className={styles.main}>
          <Outlet />
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
