import React, {useState} from "react";
import GoogleMapReact, {MapTypeStyle} from 'google-map-react';
import Geocode from 'react-geocode';
import MapsMarker from "./google-map-marker";
import OfficeMarker, {OFFICES} from "./office-marker";

type Props =
    | {
    address: string;
};


const GoogleMapsComponent = (props: Props) => {
    const [lat, setLat] = useState(0)
    const [lng, setLng] = useState(0)
    const key = "AIzaSyAEuBz35ustn3qnGJ0RDsKxMFX2nabES_4"

    Geocode.fromAddress(props.address, key).then(
        (response) => {
            const {lat, lng} = response.results[0].geometry.location;
            setLat(lat)
            setLng(lng)
        },
        (error) => {
            console.error(error);
        }
    );

    return (
        <div style={{height: '50vh', width: '100%'}}>
            {lat != 0 && <GoogleMapReact
                bootstrapURLKeys={{key: key}}
                defaultCenter={{lat: lat, lng: lng}}
                defaultZoom={12}
                center={{lat: lat, lng: lng}}
                options={styles}
            >
                <MapsMarker
                    lat={lat} // important don't drop
                    lng={lng} // important don't drop
                    text="🏘" // important don't drop
                />
                {OFFICES.map((obj, index) => <OfficeMarker key={index} lat={obj.lat} lng={obj.lng} text="🖤"/>)}
            </GoogleMapReact>}
        </div>
    );
};

const styles: Record<string, MapTypeStyle[]> = {
    styles: [
        {
            elementType: "geometry",
            stylers: [{color: "#f5f5f5"}],
        },
        {
            elementType: "labels.icon",
            stylers: [{visibility: "off"}],
        },
        {
            elementType: "labels.text.fill",
            stylers: [{color: "#616161"}],
        },
        {
            elementType: "labels.text.stroke",
            stylers: [{color: "#f5f5f5"}],
        },
        {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [{color: "#bdbdbd"}],
        },
        {
            featureType: "poi",
            elementType: "geometry",
            stylers: [{color: "#eeeeee"}],
        },
        {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{color: "#757575"}],
        },
        {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{color: "#e5e5e5"}],
        },
        {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{color: "#9e9e9e"}],
        },
        {
            featureType: "road",
            elementType: "geometry",
            stylers: [{color: "#ffffff"}],
        },
        {
            featureType: "road.arterial",
            elementType: "labels.text.fill",
            stylers: [{color: "#757575"}],
        },
        {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{color: "#dadada"}],
        },
        {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [{color: "#616161"}],
        },
        {
            featureType: "road.local",
            elementType: "labels.text.fill",
            stylers: [{color: "#9e9e9e"}],
        },
        {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [{color: "#e5e5e5"}],
        },
        {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [{color: "#eeeeee"}],
        },
        {
            featureType: "water",
            elementType: "geometry",
            stylers: [{color: "#c9c9c9"}],
        },
        {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{color: "#9e9e9e"}],
        },
    ],

};

export default GoogleMapsComponent;
