import React, { FC, useState } from 'react';
import styles from './index.module.css';
import { Image, OfferResponse } from './types';
import { useTextStyles } from '@rescui/typography';
import dayjs from 'dayjs';
import { Tag } from '@rescui/tag';
import GoogleMapsComponent from '../../components/google-maps/google-maps';
import { Pets, Plants } from '../offer-create/enums';
import cn from 'classnames';
import { PromotionTypeTag } from '../../components/promotion-type-tag';
import Button from '@rescui/button';
import { SlackIcon, SpaceIcon, TelegramIcon } from '@rescui/icons';
import Carousel from '@webteam/carousel';
import { Popup } from '@webteam/popup';
import { S3Image, S3ImageCss } from '../../components/image-downloader';

const petsList: string[] = Object.values(Pets);
const platsList: string[] = Object.values(Plants);
const careCat = '🐱';
const careDog = '🐶';
const carePlats = '🌱';

export const ContactButtons: FC<{ messengers?: string[]; spaceProfileLink?: string }> = ({
  messengers,
  spaceProfileLink,
}) => {
  if (!messengers) return null;
  const links = [...messengers].sort((a, b) => a.localeCompare(b));
  const getIcon = (s: string) => {
    if (s.startsWith('telegram:')) return <TelegramIcon />;
    if (s.startsWith('slack:')) return <SlackIcon />;
    return undefined;
  };
  const getLink = (s: string) => {
    const [prefix, userId] = s.split(':');
    if (prefix.toLowerCase() === 'telegram') return `https://telegram.me/${userId}`;
    return undefined;
  };
  return (
    <div className={styles.contactButtonsBlock}>
      {links.map((e) => {
        const icon = getIcon(e);
        const link = getLink(e);
        return link && icon && <Button key={e} icon={icon} mode="rock" onClick={() => window.open(link)} />;
      })}
      <Button icon={<SpaceIcon />} mode="rock" onClick={() => window.open(spaceProfileLink)} />
    </div>
  );
};

const PicCarousel: FC<{ images: Image[] }> = ({ images }) => {
  const [open, setOpen] = useState('');
  const cnames = ['BigOne', 'A', 'B', 'C'];

  return (
    <div className={styles.carousel} style={{ width: '100%' }}>
      {/*@ts-ignore*/}
      <Carousel>
        {sliceIntoChunks(
          images.map((i) => i.image_url),
          3,
        ).map((image, idx) => (
          <div key={idx} className={styles.container}>
            <>
              {open && (
                <Popup onRequestClose={() => setOpen('')}>
                  <div style={{ height: '80vh' }}>
                    <S3Image style={{ height: '100%', objectFit: 'contain' }} objectKey={open} />
                  </div>
                </Popup>
              )}
            </>
            {image.map((i, idx) => (
              <S3ImageCss key={i} objectKey={i} className={styles[cnames[idx]]} onClick={() => setOpen(i)} />
              /*<div
                className={styles[cnames[idx]]}
                onClick={() => setOpen(i)}
                style={{
                  borderRadius: '16px',
                  backgroundImage: `url(${imageDownloadUrl}/${i})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />*/
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export const UserPic: FC<{ link?: string }> = ({ link }) => {
  if (!link) return null;

  return (
    <S3Image
      objectKey={link}
      alt={'avatar'}
      style={{ marginRight: 8, borderRadius: '50%', objectFit: 'cover' }}
      width={24}
      height={24}
    />
  );
};

export const OwnerBlock: FC<{ promotionType: number; userName: string; avatar?: string }> = ({
  promotionType,
  userName,
  avatar,
}) => {
  const textCn = useTextStyles();
  return (
    <div className={cn(styles.ownerBlock, textCn('rs-text-3'))}>
      <div className={styles.host}>
        <UserPic link={avatar}></UserPic>
        <span>{userName}</span>
      </div>
      <PromotionTypeTag type={promotionType} />
    </div>
  );
};

const OfferCart: FC<{ data: OfferResponse }> = ({ data }) => {
  const textCn = useTextStyles();
  const careOf = [];

  const {
    promotion_by_pk: {
      user_data: { email, display_name, additional_info: userAdditionalInfo },
      location_address: {
        city,
        address,
        postcode,
        additional_info: { description: descriptionOfAddress, bedrooms },
        images,
        pets,
      },
      promotion_type,
      from_date,
      till_date,
      description,
      additional_info,
    },
  } = data;

  const petsTags = additional_info.filter((tag) => petsList.includes(tag));
  for (let buddy of petsTags) {
    switch (buddy) {
      case 'Dogs':
        careOf.push(buddy + ' ' + careDog);
        break;
      case 'Cats':
        careOf.push(buddy + ' ' + careCat);
        break;
      default:
        careOf.push(buddy);
    }
  }
  let bedCountTag = calculateBedTag(bedrooms);

  const plantsTags = additional_info.filter((tag) => platsList.includes(tag));
  for (let buddy of plantsTags) {
    careOf.push(buddy + ' ' + carePlats);
  }

  const googleUrl = 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(city + ' ' + address);

  const detailsTags = additional_info
    .filter((tag) => !platsList.includes(tag))
    .filter((tag) => !petsTags.includes(tag));
  detailsTags.push(bedCountTag);

  const petImages = pets?.[0]?.images || [];
  const imgs = images || [];
  const imagesToRender = petImages.concat(images);

  return (
    <div className={styles.offerCart}>
      <div className={styles.header}>
        <div className={textCn('rs-text-2')} style={{ margin: 0 }}>
          <a className={textCn('rs-link')} href={googleUrl} target="_blank" rel="noreferrer">
            {city}, {postcode}, {address}
          </a>
        </div>
        <OwnerBlock promotionType={promotion_type} userName={display_name} avatar={userAdditionalInfo?.avatar_link} />
      </div>
      {from_date && (
        <div className={cn(styles.dates, textCn('rs-digits-2'))}>
          {dayjs(from_date).format('D.M')} — {till_date === null ? '...' : dayjs(till_date).format('D.M')}{' '}
          <span className={styles.flame}>🔥</span>
        </div>
      )}
      {imagesToRender.length > 0 && imagesToRender.length > 1 ? (
        <PicCarousel images={imagesToRender} />
      ) : (
        <S3Image className={styles.soloImage} objectKey={imagesToRender[0].image_url} />
      )}
      <div className={styles.contentContainer}>
        <div className={styles.info}>
          {careOf.length > 0 && (
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <p className={textCn('rs-h3')} style={{ fontWeight: 600, marginRight: 8 }}>
                Details on your nice company
              </p>
              {careOf.map((e) => {
                return (
                  <div style={{ marginRight: 8 }} key={e}>
                    <Tag backgroundColor={'#F4F4F4'}>{e}</Tag>
                  </div>
                );
              })}
            </div>
          )}
          {pets?.[0]?.details && pets?.[0]?.images && (
            <>
              <div className={textCn('rs-text-2', { hardness: 'hard' })}>{pets?.[0]?.details}</div>
            </>
          )}
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <p className={cn(textCn('rs-h3'))} style={{ marginRight: 8 }}>
              Apartment details
            </p>
            {detailsTags.map((e) => {
              return (
                <div style={{ marginRight: 8 }} key={e}>
                  <Tag backgroundColor={'#F4F4F4'}>{e}</Tag>
                </div>
              );
            })}
          </div>
          <div className={textCn('rs-text-2', { hardness: 'hard' })}>{description}</div>
        </div>
        <div className={styles.mapContainer}>
          <p className={textCn('rs-h4')}>Location on the map</p>
          <div className={styles.map}>
            <GoogleMapsComponent address={`${address}, ${postcode}, ${city}`} />
          </div>
        </div>
      </div>
      <ContactButtons
        messengers={userAdditionalInfo?.messengers}
        spaceProfileLink={userAdditionalInfo?.spaceProfileLink}
      />
    </div>
  );
};

function calculateBedTag(bedrooms: number) {
  if (bedrooms === 0) {
    return 'Studio';
  } else if (bedrooms === 1) {
    return '1 Bedroom';
  } else {
    return bedrooms + ' Bedrooms';
  }
}

function sliceIntoChunks(arr: string[], chunkSize: number) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

export default OfferCart;
