import React from 'react';
import styles from './index.module.css';

import logoImage from './logo.svg';

const Header = () => {
  return (
    <header className={styles.header}>
      <img className={styles.logo} src={logoImage} alt={''} />
    </header>
  );
};

export default Header;
