import React, { FC, useEffect, useMemo } from 'react';
import { FeedFilter } from '../../components/filter/feed-filter';
import { Card } from '../../components/card/card';
import { gql, useQuery } from '@apollo/client';
import { groupByCounting, useErrorLog } from '../../misc';
import { FeedResponse, FeedResponseShort, Promotion } from './data';
import styles from './index.module.css';
import { Loading } from '../../components/loading';
import { useAuthentication } from '../../components/auth/state';
import useSignOut from "../../components/auth/useSignOut";
import { useSearchParams } from "react-router-dom";

const GET_OFFERS = gql`
  query promotion_details_with_pets {
    promotion(where: { archived: { _eq: false } }) {
      id
      from_date
      till_date
      promotion_type
      additional_info
      user_data {
        id
        email
        additional_info
        display_name
      }
      location_address {
        country
        city
        address
        postcode
        additional_info
        images(limit: 1) {
          image_url
        }
      }
    }
  }
`;

const GET_ARCHIVED = (userId: string | undefined) => gql`
query own_archived_promotions {
  promotion(where: {_and: {
    user_id: {_eq: "${userId}"}, 
    archived: {_eq: true}
  }}) {
    id
  }
}
`;

const Cards: FC<{ data: Promotion[] }> = ({ data }) => {
  return (
    <div className={styles.cards}>
      {data.map((promotion) => {
        return <Card key={promotion.id} promotion={promotion} />;
      })}
    </div>
  );
};

const LOCATION_PARAM_NAME = 'location';

const FeedPage: FC<{}> = ({}) => {
  const { basicProfile } = useAuthentication();
  const { data, loading, error, refetch } = useQuery<FeedResponse>(GET_OFFERS);
  const { data: archivedData, refetch: refecthArchive } = useQuery<FeedResponseShort>(
    GET_ARCHIVED(basicProfile?.email),
  );
  const signOut = useSignOut();

  const ownPromotionId = useMemo(() => {
    return (
      data?.promotion?.find(({ user_data: userData }) => userData.id === basicProfile?.email)?.id ||
      archivedData?.promotion[0]?.id
    );
  }, [data, basicProfile, archivedData]);
  const rawData = useMemo(() => data?.promotion ?? [], [data]);
  const countries = groupByCounting(rawData, ({ location_address: { country } }) => country);

  const [searchParams, setSearchParams] = useSearchParams()
  const selectedCountry = searchParams.get(LOCATION_PARAM_NAME)

  function changeCountry(location: string | null) {
    const nextParams = new URLSearchParams(searchParams)
    if (location !== null) {
      nextParams.set(LOCATION_PARAM_NAME, location.toLowerCase())
    } else {
      nextParams.delete(LOCATION_PARAM_NAME)
    }

    setSearchParams(nextParams)
  }

  const filtered = rawData.filter(({ location_address: { country } }) =>
    selectedCountry ? country.toLowerCase() === selectedCountry.toLowerCase() : true,
  );
  const fireOffers = filtered.filter((p) => p.from_date).sort((a, b) => compare(a, b));
  const stateOffers = filtered.filter((p) => !p.from_date);

  useErrorLog(error);

  useEffect(() => {
    refetch();
    refecthArchive();
  }, []);

  useEffect(() => {
    if (!loading && error) {
      signOut();
    }
  }, [error]);

  if (loading) return <Loading />;

  return (
    <div>
      <FeedFilter
          selectedCountry={selectedCountry}
          countriesMap={countries}
          onCountryChange={changeCountry}
          ownPromotionId={ownPromotionId}
      />
      <Cards data={fireOffers.concat(stateOffers)} />
    </div>
  );
};

function compare(a: Promotion, b: Promotion) {
  if (!a.from_date) return -1;
  if (!b.from_date) return -1;
  if (a.from_date < b.from_date) {
    return 0;
  }
  return 1;
}

export default FeedPage;
