import create from 'zustand';
import { devtools } from 'zustand/middleware';
import jwtDecode from 'jwt-decode';

export interface BasicProfile {
  name?: any;
  email?: any;
  picture?: any;
}

interface AuthenticatedUser {
  idToken?: string;
  setIdToken: (t?: string) => void;
  basicProfile?: BasicProfile;
  setBasicProfile: (p: any) => void;
}

const getBasicProfile = () => {
  const idToken = localStorage.getItem('token');

  if (!idToken) {
    return undefined;
  }

  const { name, email, picture } = jwtDecode<BasicProfile>(idToken);

  return { name, email, picture };
};

export const useAuthentication = create<AuthenticatedUser>(
  // @ts-ignore
  devtools((set) => ({
    idToken: localStorage.getItem('token') || undefined,
    setIdToken: (token?: string) => set({ idToken: token }),
    basicProfile: getBasicProfile(),
    setBasicProfile: (basicProfile: any) => set({ basicProfile }),
  })),
);
