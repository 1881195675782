import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useTextStyles } from '@rescui/typography';
import Switcher from '@rescui/switcher';
import Input from '@rescui/input';
import styles from './index.module.css';
import cn from 'classnames';
import { Chip, ChipList } from '@rescui/chip-list';
import { Textarea } from '@rescui/textarea';
import { Checkbox } from '@rescui/checkbox';
import Button from '@rescui/button';
import { countries, Country, DatesType, Feature, getCountryCities, Pets, Plants, Requirement } from './enums';
import { MultiChipList, ValueType } from '../../components/multichiplist';
import { useMutation, useQuery } from '@apollo/client';
import { useAuthentication } from '../../components/auth/state';
import { SEND_OFFER } from './mutation';
import RangePicker from '../../components/rangepicker';
import { ImageUploader } from '../../components/image-uploader';
import { useNavigate, useParams } from 'react-router';
import dayjs from 'dayjs';
import { offerSchema } from './schema';
import { s3ImageUploader } from '../../misc/s3-image-uploader';
import { getOfferData } from '../offer-view';
import { Image, OfferResponse } from '../offer-view/types';
import { imageDownloadUrl } from '../../misc';

interface Values {
  requirements: Requirement;
  datesType: DatesType;
  country: Country;
  city: string;
  nBedrooms: number;

  fromDate?: string;
  toDate?: string;

  postCode: string;
  address: string;
  surname: string;

  features: ValueType[];
  pets: ValueType[];
  plants: ValueType[];
  description: string;
  petsDetails: string;

  otherCity?: string;

  notifySlack: boolean;
  images: File[];
  petImages: File[];
}

const initialValues: Values = {
  requirements: Requirement.PetSitting,
  datesType: DatesType.Exact,
  country: Country.Germany,
  city: '',
  nBedrooms: 0,

  postCode: '',
  address: '',
  surname: '',

  features: [],
  pets: [],
  plants: [],
  description: '',
  petsDetails: '',

  notifySlack: true,
  images: [],
  petImages: [],
};

const mapDataToForm = (data: OfferResponse | undefined) => {
  if (!data || !data.promotion_by_pk) {
    return { images: [], petImages: [] };
  }

  const {
    promotion_by_pk: {
      // user_data: {additional_info: userAdditionalInfo},
      location_address: {
        country,
        city,
        address,
        postcode,
        additional_info: { bedrooms },
        images,
        pets,
      },
      id,
      promotion_type,
      from_date,
      till_date,
      description,
      additional_info,
    },
  } = data;

  return {
    requirements: promotion_type,
    datesType: from_date || till_date ? DatesType.Exact : DatesType.Flexible,
    fromDate: from_date,
    toDate: till_date,
    country,
    city,
    nBedrooms: Number(bedrooms),

    postCode: postcode,
    address,
    surname: '',

    features: additional_info.filter((i) => Object.values(Feature).includes(i as Feature)),
    pets: additional_info.filter((i) => Object.values(Pets).includes(i as Pets)),
    plants: additional_info.filter((i) => Object.values(Plants).includes(i as Plants)),
    description,
    petsDetails: pets[0]?.details,

    notifySlack: !id,
    images,
    petImages: pets[0]?.images,
  };
};

const makeFile = async (i: Image, token: string | undefined) => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${token}`);
  const image = await fetch(`${imageDownloadUrl}/${i.image_url}`, { headers });
  const imageBlob = await image.blob();
  const preview = URL.createObjectURL(imageBlob);

  return Object.assign(imageBlob, { preview });
};

export const FormPanel = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const textCn = useTextStyles();
  const { basicProfile, idToken } = useAuthentication();
  const { data: offerData, loading: offerLoading, called } = useQuery<OfferResponse>(getOfferData(id as string));
  const [mutationValues, setMutationValues] = useState<any>(null);
  const [sendOffer, { data, loading }] = useMutation(SEND_OFFER(mutationValues));
  const [formValues, setFormValues] = useState(undefined);

  useEffect(() => {
    if (!offerLoading && called && id) {
      if (basicProfile?.email !== offerData?.promotion_by_pk?.user_data?.email) {
        navigate('/');
      }
      // @ts-ignore
      const { images = [], petImages = [], ...data } = mapDataToForm(offerData);
      const newImages = images.map((it) => makeFile(it, idToken)) || [];
      const newPetImages = petImages.map((it) => makeFile(it, idToken)) || [];

      // @ts-ignore
      setFormValues(data);

      Promise.all(newPetImages).then((res) => {
        // @ts-ignore
        setFormValues((state) => ({ ...state, petImages: res }));
      });
      Promise.all(newImages).then((res) => {
        // @ts-ignore
        setFormValues((state) => ({ ...state, images: res }));
      });
    }
  }, [offerData, offerLoading, called, id]);

  useEffect(() => {
    // window.location.reload()
    if (mutationValues) {
      sendOffer();
    }
  }, [mutationValues]);

  useEffect(() => {
    if (data) {
      navigate(`/offer/${data.insert_user_data_one.promotions[0].id}`);
    }
  }, [data]);

  const handleFormikSubmit = async (values: Values) => {
    let imageRefs = await s3ImageUploader.upload(values.images, idToken);
    let flatImagesLinksString = imageRefs
      .map((i) => encodeURIComponent(i))
      .map((i) => `{image_url: "${i}"}`)
      .join(',');

    imageRefs = await s3ImageUploader.upload(values.petImages, idToken);
    let petImageLinksString = imageRefs
      .map((i) => encodeURIComponent(i))
      .map((i) => `{image_url: "${i}"}`)
      .join(',');

    function getCity(city: string) {
      if (city === 'Other City') {
        return values.otherCity;
      } else {
        return city;
      }
    }

    function getDataTypes(s?: string) {
      if (values.datesType == DatesType.Flexible) {
        return null;
      } else {
        return dayjs(s).format('YYYY-MM-DD');
      }
    }

    const offer = {
      promotionId: id,
      displayName: basicProfile?.name,
      email: basicProfile?.email,
      promotionType: values.requirements,
      country: values.country,
      city: getCity(values.city),
      postCode: values.postCode,
      address: values.address,
      bedrooms: values.nBedrooms,
      description: values.description,
      notifySlack: values.notifySlack || 'false',
      /** Array of Features, Pets and Plants */
      additionalInfo: [...values.features, ...values.pets, ...values.plants].filter(Boolean),
      images: flatImagesLinksString,
      petImages: petImageLinksString,
      petsDetails: values.petsDetails,
      fromDate: getDataTypes(values.fromDate),
      toDate: getDataTypes(values.toDate),
    };

    setMutationValues(offer);
  };

  return (
    <Formik<Values>
      // @ts-ignore
      initialValues={formValues || initialValues}
      enableReinitialize
      onSubmit={handleFormikSubmit}
      validationSchema={offerSchema}
      validateOnChange={false}
    >
      {({
        values: {
          requirements,
          datesType,
          country,
          city,
          otherCity,
          nBedrooms,
          postCode,
          address,
          surname,
          pets,
          plants,
          description,
          features,
          petsDetails,
          notifySlack,
          fromDate,
          toDate,
          images,
          petImages,
        },
        errors,
        setFieldValue,
        handleSubmit,
        isSubmitting,
      }) => {
        const cities = getCountryCities(country);
        const isOtherCitySelected = city === cities[cities.length - 1]?.label;
        // console.log(fromDate)
        // console.log(toDate)
        return (
          <form onSubmit={handleSubmit}>
            <h4 className={textCn('rs-h4')}>General Info</h4>
            <span className={styles.generalInfoWord} />
            <p className={cn(textCn('rs-text-2', { hardness: 'hard' }), styles.groupTitle)}>Offer type</p>
            <Switcher
              value={requirements}
              options={[
                { label: 'Pet sitting', value: Requirement.PetSitting },
                { label: 'Ready to swap', value: Requirement.ReadyToSwap },
                { label: 'Couchsurfing', value: Requirement.Couchsurfing },
              ]}
              onChange={(value: any) => setFieldValue('requirements', value)}
              mode="rock"
            />
            <p className={cn(textCn('rs-text-2', { hardness: 'hard' }), styles.groupTitle)}>Dates</p>
            <p className={textCn('rs-text-2')}>
              If you are aware of the exact dates of your traveling, please fill them in. Otherwise, choose the flexible
              option.
            </p>
            <div className={styles.flex12pxGap}>
              <Switcher
                options={[
                  {
                    label: 'Flexible',
                    value: DatesType.Flexible,
                  },
                  { label: 'Choose exact date', value: DatesType.Exact },
                ]}
                value={datesType}
                onChange={(value: any) => setFieldValue('datesType', value)}
                mode="rock"
              />
              {datesType === DatesType.Exact && (
                <>
                  <RangePicker
                    toDate={toDate}
                    fromDate={fromDate}
                    onChange={({ from, to }) => {
                      from && setFieldValue('fromDate', dayjs(from).format());
                      to && setFieldValue('toDate', dayjs(to).format());
                    }}
                  />
                </>
              )}
            </div>
            <h4 className={cn(textCn('rs-h4'), styles.marginTop48)}>Location</h4>
            <span className={styles.locationWord} />
            <p className={cn(textCn('rs-text-2', { hardness: 'hard' }), styles.groupTitle)}>Country</p>
            <ChipList
              value={country}
              onChange={(value) => {
                setFieldValue('country', value);
                setFieldValue('city', 0);
              }}
              mode="rock"
            >
              {countries.map(({ label, value }) => {
                return (
                  <Chip value={label} key={value}>
                    {label}
                  </Chip>
                );
              })}
            </ChipList>
            <p className={cn(textCn('rs-text-2', { hardness: 'hard' }), styles.groupTitle)}>City</p>
            <div className={styles.flex12pxGap}>
              <ChipList value={city} onChange={(value) => setFieldValue('city', value)} mode="rock">
                {cities.map(({ label, value }) => {
                  return (
                    <Chip value={label} key={value}>
                      {label}
                    </Chip>
                  );
                })}
              </ChipList>
              {isOtherCitySelected && (
                <Input
                  value={otherCity}
                  onChange={(value) => setFieldValue('otherCity', value.target.value)}
                  placeholder="Other city"
                />
              )}
            </div>
            <p className={cn(textCn('rs-text-2', { hardness: 'hard' }), styles.groupTitle)}>Address</p>
            <div className={styles.flex12pxGap}>
              <Input
                value={postCode}
                onChange={(e) => setFieldValue('postCode', e.target.value)}
                placeholder="Postcode"
              />
              <Input
                value={address}
                onChange={(e) => setFieldValue('address', e.target.value)}
                placeholder="Street address, apt number"
              />
              <Input value={surname} onChange={(e) => setFieldValue('surname', e.target.value)} placeholder="Surname" />
            </div>
            <p className={cn(textCn('rs-text-2', { hardness: 'hard' }), styles.groupTitle)}>Bedrooms</p>
            <ChipList value={nBedrooms} onChange={(value) => setFieldValue('nBedrooms', value)} mode="rock">
              <Chip>Studio</Chip>
              <Chip>1</Chip>
              <Chip>2</Chip>
              <Chip>3</Chip>
              <Chip>4+</Chip>
            </ChipList>
            <p className={cn(textCn('rs-text-2', { hardness: 'hard' }), styles.groupTitle)}>Features</p>
            <MultiChipList
              value={features}
              onChange={(value) => setFieldValue('features', value)}
              options={[
                { label: 'Close to the JetBrains Office', value: Feature.CloseToTheOffice },
                { label: 'Close to Airport', value: Feature.CloseToTheAirport },
                { label: 'Gaming stuff', value: Feature.Gaming },
                { label: 'Garden', value: Feature.Garden },
                { label: 'Parking', value: Feature.Parking },
                { label: 'Washing machine', value: Feature.WashingMachine },
              ]}
            />
            <p className={cn(textCn('rs-text-2', { hardness: 'hard' }), styles.groupTitle)}>Apartment photos</p>
            <ImageUploader
              value={images}
              onFilesChanged={async (files) => {
                setFieldValue('images', files);
              }}
            />
            <p className={cn(textCn('rs-text-2', { hardness: 'hard' }), styles.groupTitle)}>Description</p>
            <Textarea
              value={description}
              onChange={(e) => setFieldValue('description', e.target.value)}
              minRows={6}
            ></Textarea>
            <h4 className={cn(textCn('rs-h4'), styles.marginTop48)}>
              {requirements === Requirement.PetSitting ? 'Care' : 'Home buddies'}
            </h4>
            <span className={styles.careWord} />
            <p className={cn(textCn('rs-text-2', { hardness: 'hard' }), styles.groupTitle)}>Pets</p>
            <MultiChipList
              value={pets}
              onChange={(value) => setFieldValue('pets', value)}
              options={[
                { label: 'Birds', value: Pets.Birds },
                { label: 'Cats', value: Pets.Cats },
                { label: 'Dogs', value: Pets.Dogs },
                { label: 'Fish', value: Pets.Fish },
                { label: 'Reptiles', value: Pets.Reptiles },
                { label: 'Small Pets', value: Pets.SmallPets },
                { label: 'Other Pet Friends', value: Pets.OtherPetFriends },
              ]}
            />
            <p className={cn(textCn('rs-text-2', { hardness: 'hard' }), styles.groupTitle)}>Plants</p>
            <MultiChipList
              value={plants}
              onChange={(value) => setFieldValue('plants', value)}
              options={[
                { label: 'Indoor Plants', value: Plants.Indoor },
                { label: 'Outdoor Plants', value: Plants.Outdoor },
              ]}
            />
            <p className={cn(textCn('rs-text-2', { hardness: 'hard' }), styles.groupTitle)}>Additional Details</p>
            <Textarea
              value={petsDetails}
              onChange={(e) => setFieldValue('petsDetails', e.target.value)}
              minRows={4}
              placeholder={'Your pet’s name, age, specific info, etc.'}
            ></Textarea>
            <p className={cn(textCn('rs-text-2', { hardness: 'hard' }), styles.groupTitle)}>Pets/Plants Photos</p>
            <div className={styles.petImages}>
              <ImageUploader
                key={2}
                value={petImages}
                onFilesChanged={async (files) => {
                  setFieldValue('petImages', files);
                }}
              />
            </div>
            <Checkbox checked={notifySlack} onChange={(e) => setFieldValue('notifySlack', e.target.checked)}>
              I want to notify the members of the hospitality channels in Slack and Space about my listing.
            </Checkbox>
            {errors &&
              Object.entries(errors).map(
                ([k, v]) =>
                  /*@ts-ignore*/
                  errors[k] && (
                    <div className={'rs-text-3'} style={{ color: 'var(--rs-color-warning)' }}>
                      {typeof v === 'string' ? v : ''}
                    </div>
                  ),
              )}
            <Button
              busy={isSubmitting}
              disabled={loading}
              type={'submit'}
              mode="rock"
              size="l"
              className={styles.marginTop12}
            >
              Publish
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
