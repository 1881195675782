import React from 'react';
import { useTextStyles } from '@rescui/typography';
import cn from 'classnames';
import styles from './index.module.css';
import { SignInButton } from '../../components/auth/button';

const LandingPage = () => {
  const textCn = useTextStyles();

  return (
    <div style={{ marginTop: '120px' }}>
      <>
        <p className={cn(textCn('rs-text-1', { hardness: 'hard' }), styles.title)}>
          A service to help colleagues <span className={styles.swapWord}>swap</span>
          <br /> <span className={styles.homesWord}>homes</span> and find{' '}
          <span className={styles.theMostCaringWord}>the most caring</span> <span className={styles.petWord}>pet</span>
          <br /> <span className={styles.sittersWord}>sitters</span> among JetBrainers.
        </p>
        <SignInButton className={styles.signinBtn} />
      </>
    </div>
  );
};

export default LandingPage;
