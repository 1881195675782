import { Chip, ChipList } from '@rescui/chip-list';
import styles from './filter.module.css';
import React, { FC } from 'react';
import Button from '@rescui/button';
import { useNavigate } from 'react-router';
import { PenIcon, PlusIcon } from '@rescui/icons';
import { SignOutButton } from '../auth/button';
import Tooltip from '@rescui/tooltip';
import { withFlagEmoji } from '../../misc';

export const FeedFilter: FC<{
  selectedCountry: string | null;
  countriesMap: { [key: string]: number };
  onCountryChange: (country: string | null) => void;
  ownPromotionId?: string | null | undefined;
}> = ({ selectedCountry, countriesMap, onCountryChange, ownPromotionId }) => {
  const totalCountries = Object.entries(countriesMap)
    .map(([_, v]) => v)
    .reduce((acc: number, v: number) => acc + v, 0);
  const entries: [string, number][] = [
    ['All Countries', totalCountries],
    ...Object.entries(countriesMap).sort((a, b) => b[1] - a[1]),
  ];
  const navigate = useNavigate();

  let selectedCountryIndex = selectedCountry === null
    ? 0
    : entries.findIndex(([country, _]) => country.toLowerCase() === selectedCountry.toLowerCase())

  if (selectedCountryIndex < 0) {
      selectedCountryIndex = 0 // should never happen
  }

  return (
    <div className={styles.root}>
      <ChipList
        value={selectedCountryIndex}
        mode="rock"
        onChange={(i: any) => {
          i === 0 ? onCountryChange(null) : onCountryChange(entries[i][0]);
        }}
        // value={country}
        // onChange={(i) => setCountry(data.location_address[Number(i)].country)}
        className="rs-docs-offset-top-12"
      >
        {entries.map(([country, n]) => {
          return (
            <Chip key={country}>
              {withFlagEmoji(country)} ({n})
            </Chip>
          );
        })}
      </ChipList>
      <div className={styles.buttonsBlock}>
        {ownPromotionId ? (
          <Tooltip content="Edit your offer" placement="left">
            <Button mode="rock" icon={<PenIcon />} onClick={() => navigate(`/offer-edit/${ownPromotionId}`)} />
          </Tooltip>
        ) : (
          <Tooltip content="New offer" placement="left">
            <Button mode="rock" icon={<PlusIcon />} onClick={() => navigate('/create-cart')} />
          </Tooltip>
        )}
        <SignOutButton />
      </div>
    </div>
  );
};
