import styles from './index.module.css';
import { SignOutButton } from '../../components/auth/button';
import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router';
import Tooltip from '@rescui/tooltip';
import Button from '@rescui/button';
import {ArrowLeftIcon, PenIcon} from '@rescui/icons';

export const NavPanel: FC<{ isEditable: boolean }> = ({ isEditable }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <div className={styles.navBar}>
      <div className={styles.userpicPanel}>
        <Tooltip content="Back home" placement="left">
          <Button icon={<ArrowLeftIcon />} mode="outline" onClick={() => navigate('/')} />
        </Tooltip>
      </div>
      <div className={styles.navBarButtons}>
        {isEditable && <Button icon={<PenIcon />} mode="rock" onClick={() => navigate(`/offer-edit/${id}`)} />}
        <SignOutButton />
      </div>
    </div>
  );
};
