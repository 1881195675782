import React from 'react';
import { useTextStyles } from '@rescui/typography';
import cn from 'classnames';

import styles from './index.module.css';
import Button from '@rescui/button';
import { SlackIcon } from '@rescui/icons';

const Footer = () => {
  const textCn = useTextStyles();

  return (
    <footer className={cn(styles.footer, textCn('rs-text-2'))}>
      <span>JetBrains Hackathon, 2022</span>
      <Button href={'https://jetbrains.slack.com/archives/C03SYN8JE86/p1665138626731249'} mode={'rock'} size={'s'} icon={<SlackIcon />} />
    </footer>
  );
};

export default Footer;
