import React from "react";
import marker from "./mark.svg"

type Props =
    | {
    lat: number;
    lng: number;
    text: string;
};


const MapsMarker = (props: Props) => {
    return (
            <img src={marker} style={{transform: "translate(-50%, -50%)", width: 50}} alt=""/>
    );
};

export default MapsMarker;