import { object, string, number, date, array } from 'yup';

export const offerSchema = object({
  requirements: number().required('Offer type is required'),
  country: string().required('Country is required'),
  city: string().required('City is required'),
  postCode: string().required('Post code is required'),
  address: string().required('Address is required'),
  images: array().min(1, 'At least one photo of the apartment is required'),
  bedrooms: number(),
  description: string().required('Description is required'),
  features: array().of(string()),
  pets: array().of(string()),
  plants: array().of(string()),
});
