import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-day-picker/dist/style.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './components/app';
import { HashRouter } from 'react-router-dom';
import '@rescui/typography/lib/font-jb-sans-auto.css';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GoogleOAuthProvider } from '@react-oauth/google';

export const CLIENT_ID = (process.env as any).REACT_APP_OAUTH_CLIENT_ID;

const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === 'production'
      ? 'https://backend.hospitality.labs.jb.gg/v1/graphql'
      : 'http://localhost:3000/v1/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId={CLIENT_ID}>
    <ApolloProvider client={client}>
      <HashRouter>
        <App />
      </HashRouter>
    </ApolloProvider>
  </GoogleOAuthProvider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
