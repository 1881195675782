import { Tag } from '@rescui/tag';
import React, { FC } from 'react';

const prettyPrintPromotionType = (type: number) => {
  return (
    {
      0: 'Pet sitting',
      1: 'Ready to swap',
      2: 'Couchsurfing',
    }[type] ?? ''
  );
};

export const PromotionTypeTag: FC<{ type: number }> = ({ type }) => {
  return (
    <Tag color="white" backgroundColor="black">
      {prettyPrintPromotionType(type)}
    </Tag>
  );
};
