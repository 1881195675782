import axios from 'axios';
import FormData from 'form-data';

const uploadUrl = (process.env as any).REACT_APP_UPLOAD_FILES_ENDPOINT;
const CHUNK_LIMIT = 4_000_000;

export const s3ImageUploader = {
    upload: async function (files: File[], token?: string): Promise<string[]> {
        if (!token || files.length === 0) return [];

        const fileChunks = files.filter(({size}) => size <= CHUNK_LIMIT)
            .reduce((acc, cur) => {
                let chunkInd = acc.findIndex(({size}) => size + cur.size <= CHUNK_LIMIT);

                if (chunkInd > -1) {
                    acc[chunkInd].size += cur.size;
                    acc[chunkInd].files.push(cur);
                } else {
                    acc.push({size: cur.size, files: [cur]});
                }
                return acc;
            }, [] as {size: number, files: File[]}[]);

        const filesUpload = fileChunks.map((fc) => {
            const _files = fc.files;
            const formData = new FormData();
            _files.forEach((f, i) => formData.append(`file${i}`, f, f.name));
            return axios.post(uploadUrl, formData, {
                headers: {
                    authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
        });

        return (await Promise.all(filesUpload)).flatMap(({data}) => data);
    },
};
