import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from '../layout';
import LandingPage from '../../pages/landing';
import CreateCartPage from '../../pages/offer-create';
import styles from './app.module.css';
import FeedPage from '../../pages/feed';
import { OfferDetails } from '../../pages/offer-view';
import { useAuthentication } from '../auth/state';

const authRouterSwitch = (isAuthorized: boolean) => {
  if (isAuthorized)
    return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index={true} element={<FeedPage />} />
          <Route path="create-cart" element={<CreateCartPage />} />
          <Route path="offer/:id" element={<OfferDetails />} />
          <Route path="offer-edit/:id" element={<CreateCartPage />} />
          <Route path="*" element={<FeedPage />} />
        </Route>
      </Routes>
    );

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index={true} element={<LandingPage />} />
        <Route path="*" element={<LandingPage />} />
      </Route>
    </Routes>
  );
};

const App = () => {
  const { idToken } = useAuthentication();

  return <div className={styles.root}>{authRouterSwitch(idToken != null)}</div>;
};

export default App;
