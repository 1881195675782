import Button from '@rescui/button';
import { FC, useEffect, useState } from 'react';
import styles from './index.module.css';

export type ValueType = string | number;

export interface MultiChipListOption {
  label: string;
  value: ValueType;
}

export const MultiChipList: FC<{
  options: MultiChipListOption[];
  value: ValueType[];
  onChange: (value: ValueType[]) => void;
}> = ({ value, options, onChange }) => {
  const [selectedOptions, setSelectedOptions] = useState<ValueType[]>(value);

  const handleClick = (value: ValueType) => {
    revert(value);
  };

  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  const revert = (value: ValueType) =>
    setSelectedOptions((prev: ValueType[] | null | undefined) => {
      return prev?.includes(value) ? prev.filter((e) => e != value) : [...(prev || []), value];
    });

  return (
    <div className={styles.wrapper}>
      {options.map(({ label, value }) => {
        return (
          <Button
            mode={selectedOptions?.includes(value) ? 'rock' : 'outline'}
            key={value}
            onClick={() => handleClick(value)}
          >
            {label}
          </Button>
        );
      })}
    </div>
  );
};
