export enum Requirement {
  PetSitting,
  ReadyToSwap,
  Couchsurfing,
}


export enum DatesType {
  Flexible = 'Flexible',
  Exact = 'Exact',
}

export enum Country {
  CzechRepublic = 'Czech Republic',
  Germany = 'Germany',
  Netherlands = 'Netherlands',
  Serbia = 'Serbia',
  Finland = 'Finland',
  Cyprus = 'Cyprus',
  Armenia = 'Armenia',
  OtherCountry = 'Other Country',
}

export const countries = [
  { label: 'Czech Republic', value: Country.CzechRepublic },
  { label: 'Germany', value: Country.Germany },
  { label: 'Netherlands', value: Country.Netherlands },
  { label: 'Serbia', value: Country.Serbia },
  { label: 'Cyprus', value: Country.Cyprus },
  { label: 'Finland', value: Country.Finland },
  { label: 'Armenia', value: Country.Armenia },
  { label: 'Other Country', value: Country.OtherCountry },
];

const cityByCountry = {
  [Country.Germany]: [
    { label: 'Berlin', value: 0 },
    { label: 'Munich', value: 1 },
    { label: 'Cologne', value: 2 },
    { label: 'Frankfurt am Main', value: 3 },
    { label: 'Hamburg', value: 4 },
    { label: 'Other City', value: 5 },
  ],
  [Country.Armenia]: [
    { label: 'Yerevan', value: 0 },
    { label: 'Gyumri', value: 1 },
    { label: 'Vanadzor', value: 2 },
    { label: 'Vagharshapat', value: 3 },
    { label: 'Abovyan', value: 4 },
    { label: 'Other City', value: 5 },
  ],
  [Country.Cyprus]: [
    { label: 'Nicosia', value: 0 },
    { label: 'Limassol', value: 1 },
    { label: 'Larnaca', value: 2 },
    { label: 'Stróvolos', value: 3 },
    { label: 'Paphos', value: 4 },
    { label: 'Other City', value: 5 },
  ],
  [Country.Serbia]: [
    { label: 'Belgrade', value: 0 },
    { label: 'Novi Sad', value: 1 },
    { label: 'Niš', value: 2 },
    { label: 'Kragujevac', value: 3 },
    { label: 'Subotica', value: 4 },
    { label: 'Other City', value: 5 },
  ],
  [Country.Netherlands]: [
    { label: 'Amsterdam', value: 0 },
    { label: 'Rotterdam', value: 1 },
    { label: 'The Hague', value: 2 },
    { label: 'Utrecht', value: 3 },
    { label: 'Eindhoven', value: 4 },
    { label: 'Other City', value: 5 },
  ],
  [Country.CzechRepublic]: [
    { label: 'Prague', value: 0 },
    { label: 'Brno', value: 1 },
    { label: 'Ostrava', value: 2 },
    { label: 'Plzeň', value: 3 },
    { label: 'Liberec', value: 4 },
    { label: 'Other City', value: 5 },
  ],
  [Country.Finland]: [
    { label: 'Helsinki', value: 0 },
    { label: 'Espoo', value: 1 },
    { label: 'Turku', value: 2 },
    { label: 'Tempere', value: 3 },
    { label: 'Oulu', value: 4 },
  ],
  [Country.OtherCountry]: [{ label: 'Other City', value: 0 }],
};

export const getCountryCities = (country: Country) => {
  return cityByCountry[country] || [];
};

export enum Feature {
  CloseToTheOffice = 'Close to the JetBrains Office',
  CloseToTheAirport = 'Close to the Airport',
  Gaming = 'Gaming stuff',
  Garden = 'Garden',
  Parking = 'Parking',
  WashingMachine = 'Washing machine',
}

export enum Pets {
  Birds = 'Birds',
  Cats = 'Cats',
  Dogs = 'Dogs',
  Fish = 'Fish',
  Reptiles = 'Reptiles',
  SmallPets = 'Small Pets',
  OtherPetFriends = 'Other Pet Friends',
}

export enum Plants {
  Indoor = 'Indoor plants',
  Outdoor = 'Outdoor plants',
}
