import {gql} from '@apollo/client';

function addslashes(str: string): string {
    return (str + '').replace(/[\\"]/g, '\\$&')
        .replace(/\u0000/g, '\\0')
        .replace(/\n/g, "\\n")
        .replace(/\r/g, "\\r");
}

export const SEND_OFFER = (values: any) => {
    if (!values)
        return gql`
      mutation {
        location_address {
          id
        }
      }
    `;
    const query = `
  mutation send_offer {
    ${values.promotionId ? `delete_promotion_by_pk(id: "${values.promotionId}") { id }` : ''}
    insert_user_data_one(
      on_conflict: { constraint: user_data_pkey, update_columns: [display_name, email] }
      object: {
        display_name: "${addslashes(values.displayName)}"
        email: "${addslashes(values.email)}"
        promotions: {
          data: [
            {
              promotion_type: ${values.promotionType}
              additional_info: ${JSON.stringify(values.additionalInfo)}
              description: "${addslashes(values.description)}"
              ${values.fromDate ? `from_date: "${addslashes(values.fromDate)}"` : ''}
              ${values.toDate ? `till_date: "${addslashes(values.toDate)}"` : ''}
              with_notification: ${values.notifySlack}
              location_address: {
                data: {
                  country: "${addslashes(values.country)}"
                  city: "${addslashes(values.city)}"
                  postcode: "${addslashes(values.postCode)}"
                  address: "${addslashes(values.address)}"
                  additional_info: {
                    bedrooms: "${addslashes(values.bedrooms)}"
                  }
                  ${
        values.images
            ? `images: {
                    data: [${values.images}] 
                  }`
            : ``
    }
                  ${
        values.petImages
            ? `pets: {
                    data: [
                      {
                        details: "${addslashes(values.petsDetails)}"
                        additional_info: {}
                        ${
                values.petImages
                    ? `images: {
                                          data: [${values.petImages}]
                                        }`
                    : ``
            }
                      }
                    ]
                  }`
            : ''
    }
              }
            }  
          }]
        }
      }
    ) {
      promotions(order_by: {created_at: desc}) {
        id
      }
    }
  }
`;
    // debugger
    return gql`
    ${query}
  `;
};
