import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import styles from './index.module.css';
import { useTextStyles } from '@rescui/typography';
import { PlusIcon, TrashIcon } from '@rescui/icons';
import Button from '@rescui/button';

const maxLength = 1024 * 1024 * 5;

const validator = (file: File) => {
  const path: string = file.name;
  const extension = path.split('.').pop() ?? '';
  const lowercaseExtension = extension.toLowerCase();
  if (!['png', 'jpg', 'jpeg'].includes(lowercaseExtension)) {
    return {
      code: 'file-invalid',
      message: `Invalid format, please use .jpg, .jpeg or .png`,
    };
  }
  if (file.size > maxLength) {
    return {
      code: 'file-invalid',
      message: `File is larger than ${maxLength} bytes`,
    };
  }
  return null;
};

export const ImageUploader: FC<{ value: File[]; onFilesChanged?: (files: File[]) => void }> = ({
  value = [],
  onFilesChanged,
}) => {
  const textCn = useTextStyles();
  const [files, setFiles] = useState<(File & { preview: string })[]>([]);
  // // changed to ref to avoid race-conditions
  // const filesRef = useRef<(File & { preview: string })[]>([]);
  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    validator,
    onDrop: (acceptedFiles: File[]) => {
      // Update the reference with the new files
      setFiles([
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      ]);
    },
  });

  // Update the reference when the `value` prop changes
  useEffect(() => {
    if (value && value.length > 0) {
      setFiles(
        value.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    }
  }, [value.length]);

  const handleRemoveClick = (name: string) => {
    setFiles(files.filter((f) => f.name !== name));
  };
  const fileRejectionItems = fileRejections.map(({ file, errors }: FileRejection) => {
    const path = (file as any).path;
    return (
      <li key={path} className={textCn('rs-text-3')}>
        {path}
        <ul>
          {errors.map((e) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    );
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files?.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    if (files.length !== value?.length) {
      onFilesChanged?.(files);
    }
  }, [value.length, onFilesChanged]);

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })} className={styles.wrapper}>
        <input {...getInputProps()} />
        <div className={styles.warapper2}>
          <div className={styles.addPhotoLabel}>
            <Button icon={<PlusIcon />} mode="rock" />
            <p className={textCn('rs-text-3')}>
              Add your photos here. <br />
              Add at least 1 photo
            </p>
          </div>
        </div>
      </div>
      <aside>
        {files.length > 0 && (
          <>
            <h4 className={textCn('rs-h4')}>Files</h4>
            <div className={styles.preview}>
              {files.map((file) => (
                <div className={styles.imageWrapper}>
                  <img
                    className={styles.previewImage}
                    src={file.preview}
                    // Revoke data uri after image is loaded
                    onLoad={() => {
                      URL.revokeObjectURL(file.preview);
                    }}
                    alt=""
                  />
                  <Button
                    onClick={() => handleRemoveClick(file.name)}
                    className={styles.removeButton}
                    icon={<TrashIcon />}
                    mode={'rock'}
                  />
                </div>
              ))}
            </div>
          </>
        )}
        {fileRejectionItems?.length > 0 && (
          <>
            <h4 className={textCn('rs-h4')} style={{ color: '#F45C4A' }}>
              Rejected files
            </h4>
            <ul>{fileRejectionItems}</ul>
          </>
        )}
      </aside>
    </section>
  );
};
