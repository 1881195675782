import {gql} from "@apollo/client";

export const REMOVE_OFFER_GQL = (promotionId: string | null | undefined) => {
    return gql`
mutation {
  delete_promotion_by_pk(id : "${promotionId}") {
    id
  }
}
    `;
}

export const DEACTIVATE_OFFER_GQL = (id: string | null | undefined) => {
    return gql`
        mutation {
            update_promotion_by_pk(pk_columns: {id: "${id}"}, _set: {archived:true}) { id }
        }
    `;
}