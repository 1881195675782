import { useTextStyles } from '@rescui/typography';
import React, { FC } from 'react';

export const Loading: FC = () => {
  const textCn = useTextStyles();
  return (
    <div className={textCn('rs-text-2')} style={{ textAlign: 'center' }}>
      Loading...
    </div>
  );
};
