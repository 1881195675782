import { useEffect } from 'react';
import exp from 'constants';
import { countries, Country } from '../pages/offer-create/enums';

export const imageDownloadUrl: string = (process.env as any).REACT_APP_IMAGE_DOWNLOAD_ENDPOINT;

export const useErrorLog = (error?: any) => {
  useEffect(() => {
    if (error) console.error(error);
  }, [error]);
};

export const groupByCounting = <T>(data: T[], keySelector: (d: T) => any) => {
  return data.reduce((acc, entry) => {
    const key = keySelector(entry);
    acc[key] = (acc[key] ?? 0) + 1;
    return acc;
  }, {} as any);
};

export const groupBy = <T>(data: T[], keySelector: (d: T) => any) => {
  return data.reduce((acc, entry) => {
    const key = keySelector(entry);
    acc[key] = [...(acc[key] ?? []), entry];
    return acc;
  }, {} as any);
};

export const withFlagEmoji = (country: string) => {
  const flagMap: any = {
    'all countries': '*️',
    [Country.Germany.toLowerCase()]: '🇩🇪',
    [Country.Armenia.toLowerCase()]: '🇦🇲',
    [Country.Cyprus.toLowerCase()]: '🇨🇾',
    [Country.Serbia.toLowerCase()]: '🇷🇸',
    [Country.Finland.toLowerCase()]: '🇫🇮',
    [Country.Netherlands.toLowerCase()]: '🇳🇱',
    [Country.CzechRepublic.toLowerCase()]: '🇨🇿',
    [Country.OtherCountry.toLowerCase()]: '🏴󠁧󠁤󠀰󠀵󠁿',
  };

  return [flagMap[country.toLowerCase()], country].filter((e) => e).join(' ');
};
