import React, { FC, useEffect, useState } from 'react';
import Input from '@rescui/input';
import styles from './index.module.css';
import { DateRange, DayPicker } from 'react-day-picker';
import dayjs from 'dayjs';
import Dropdown from '@rescui/dropdown';

const RangePicker: FC<{
  onChange?: (range: DateRange) => void;
  fromDate: string | undefined;
  toDate: string | undefined;
}> = ({ fromDate, toDate, onChange }) => {
  const [open, setOpen] = useState(false);
  // @ts-ignore
  const [range, setRange] = useState<DateRange | undefined>();
  const fromValue = range?.from ? dayjs(range.from).format('YYYY-MM-DD') : undefined;
  const toValue = range?.to ? dayjs(range?.to).format('YYYY-MM-DD') : undefined;

  useEffect(() => {
    let newRange = { ...range, from: fromDate ? new Date(fromDate) : undefined };

    if (toDate) {
      newRange.to = new Date(toDate);
    }

    setRange(newRange);
  }, [fromDate, toDate]);

  return (
    <Dropdown
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      trigger={
        <div className={styles.wrapper}>
          <Input placeholder="From" value={fromValue} onClick={() => setOpen((prev) => !prev)}></Input>
          <Input placeholder="To" value={toValue} onClick={() => setOpen((prev) => !prev)} />
        </div>
      }
    >
      <DayPicker
        style={{ font: 'JetBrains Sans' }}
        mode="range"
        onSelect={(e) => {
          setRange(e);
          onChange?.(e as any);
        }}
        selected={range}
      />
    </Dropdown>
  );
};

export default RangePicker;
