import React, { FC, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import OfferCart from './offer-cart';
import { OfferResponse } from './types';
import { NavPanel } from './nav-panel';
import { Loading } from '../../components/loading';
import { useAuthentication } from '../../components/auth/state';
import useSignOut from '../../components/auth/useSignOut';

export const getOfferData = (id: string) => gql`
  query promotion_details {
    promotion_by_pk(id: "${id}") {
      id
      from_date
      till_date
      description
      archived
      promotion_type
      additional_info
      user_data {
        id
        email
        display_name
        additional_info
      }
      location_address {
        country
        city
        postcode
        address
        additional_info
        images {
          image_url
        }
        pets {
          details
          images {
            image_url
          }
        }
      }
    }
  }
`;

export const OfferDetails: FC<{}> = ({}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { basicProfile } = useAuthentication();
  const { data, loading, error } = useQuery<OfferResponse>(getOfferData(id as string));
  const signOut = useSignOut();

  useEffect(() => {
    if (!loading && error) {
      signOut();
    }
  }, [error]);

  if (loading) return <Loading />;

  if (!data?.promotion_by_pk) {
    navigate('/');
  }

  const isEditable = data?.promotion_by_pk?.user_data?.email === basicProfile?.email;

  return (
    <div>
      <NavPanel isEditable={isEditable} />
      <OfferCart data={data as OfferResponse} />
    </div>
  );
};
