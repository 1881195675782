import React, {useEffect, useState} from 'react';
import {imageDownloadUrl} from "../../misc";
import {useAuthentication} from "../auth/state";

interface OwnProps {
    objectKey: string;
    className: string;
    onClick: () => void;
}

const S3ImageCss = ({
                            className,
                            objectKey,
                            onClick,
                        }: OwnProps) => {
    const {idToken: token} = useAuthentication();
    const [backgroundUrl, setBackgroundUrl] = useState<string>('');

    useEffect(() => {
        if (!objectKey) return;
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);

        fetch(`${imageDownloadUrl}/${objectKey}`, {headers})
            .then(response => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.arrayBuffer();
            })
            .then(buffer => {
                const base64 = btoa(
                    new Uint8Array(buffer).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        ''
                    )
                );
                setBackgroundUrl(`data:image/png;base64,${base64}`);
            })
            .catch(error => {
                console.error("There was a problem:", error);
            });
    }, [objectKey, imageDownloadUrl]);

    return (
        <div
            className={className}
            onClick={onClick}
            style={{
                borderRadius: '16px',
                backgroundImage: `url(${backgroundUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        />
    );
};

export default S3ImageCss;
