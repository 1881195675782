import React from "react";

type Props =
    | {
    lat: number;
    lng: number;
    text: string;
};

export const OFFICES = [
    {
        lat: 52.3556969,
        lng: 4.9049371
    }, // AMSTERDAM
    {
        lat: 52.3379404,
        lng: 4.8914127
    }, // AMSTERDAM
    {
        lat: 48.1458657,
        lng: 11.5031042
    }, // MUNICH
    {
        lat: 52.5001081,
        lng: 13.4453046
    }, // BERLIN
    {
        lat: 34.7798747,
        lng: 32.4208316
    }, // CYPRUS
    {
        lat: 44.8061326,
        lng: 20.3993534
    }, // SERBIA
    {
        lat: 50.051941,
        lng: 14.4293443
    }, // PRAGUE
]

const OfficeMarker = (props: Props) => {
    return <div style={{fontSize: 20}}>🖤</div>
};
export default OfficeMarker
