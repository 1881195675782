import Button from '@rescui/button';
import React, { FC } from 'react';
import styles from './index.module.css';
import { HomeIcon, TrashIcon } from '@rescui/icons';
import { useNavigate, useParams } from 'react-router';
import { SignOutButton } from '../../components/auth/button';
import { FormPanel } from './form';
import { useAuthentication } from '../../components/auth/state';
import { useTextStyles } from '@rescui/typography';
import Tooltip from '@rescui/tooltip';
import cn from 'classnames';
import { useMutation, useQuery } from '@apollo/client';
import { OfferResponse } from '../offer-view/types';
import { Loading } from '../../components/loading';
import { getOfferData } from '../offer-view';
import { DEACTIVATE_OFFER_GQL, REMOVE_OFFER_GQL } from '../../components/filter/mutation';

const NavPanel: FC<{ isEditable: boolean; promotionId?: string; archived?: boolean }> = ({
  isEditable,
  promotionId,
  archived,
}) => {
  const navigate = useNavigate();
  const { basicProfile } = useAuthentication();
  const textCn = useTextStyles();

  const [callRemoveOffer, { data, loading }] = useMutation(REMOVE_OFFER_GQL(promotionId));

  const removeOffer = async () => {
    if (promotionId) {
      await callRemoveOffer();
      window.location.reload();
    }
  };

  const [callDeactivate, { data: deactivateData, loading: deactivateLoading }] = useMutation(
    DEACTIVATE_OFFER_GQL(promotionId),
  );

  const deactivate = async () => {
    if (promotionId) {
      await callDeactivate();
      navigate('/');
    }
  };

  return (
    <div className={styles.navBar}>
      <div className={styles.userpicPanel}>
        <img src={basicProfile?.picture} width={24} height={24} alt="userpic" className={styles.userpicImg} />
        <p className={cn(textCn('rs-text-2', { hardness: 'hard' }), styles.userpicName)}>
          {basicProfile?.name ?? '???'}
        </p>
      </div>
      <div className={styles.navBarButtons}>
        {isEditable && !archived && (
          <Tooltip content="Hide your offer from the feed" placement="left">
            <Button disabled={deactivateLoading} mode="rock" onClick={deactivate}>
              Deactivate
            </Button>
          </Tooltip>
        )}
        {isEditable && (
          <Tooltip content="Remove your offer" placement="bottom">
            <Button disabled={loading} mode="rock" icon={<TrashIcon />} onClick={removeOffer} />
          </Tooltip>
        )}
        <Tooltip content="Back home" placement="top">
          <Button icon={<HomeIcon />} mode="rock" onClick={() => navigate('/')} />
        </Tooltip>
        <SignOutButton />
      </div>
    </div>
  );
};

const CreateCartPage = () => {
  const { id } = useParams();
  const { basicProfile } = useAuthentication();
  const { data, loading } = useQuery<OfferResponse>(getOfferData(id as string));
  const archived = data?.promotion_by_pk?.archived;
  if (loading) return <Loading />;

  const isEditable = data?.promotion_by_pk?.user_data?.email === basicProfile?.email;
  return (
    <div>
      <NavPanel isEditable={isEditable} promotionId={id} archived={archived} />
      <FormPanel />
    </div>
  );
};

export default CreateCartPage;
