import { useAuthentication } from './state';
import { googleLogout } from '@react-oauth/google';
import { useCallback } from 'react';

export default function useSignOut() {
  const { setBasicProfile, setIdToken } = useAuthentication();

  return useCallback(() => {
    googleLogout();
    setIdToken(undefined);
    setBasicProfile(undefined);
    localStorage.removeItem('token');
  }, [setBasicProfile, setIdToken]);
}
