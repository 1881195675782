import React, { FC } from 'react';
import Button from '@rescui/button';
import { BasicProfile, useAuthentication } from './state';
import { ExitIcon } from '@rescui/icons';
import Tooltip from '@rescui/tooltip';
import { GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import useSignOut from './useSignOut';

export const SignInButton: FC<{ className?: string }> = () => {
  const { setIdToken, setBasicProfile } = useAuthentication();

  return (
    <div style={{ maxWidth: 275, marginTop: 48 }}>
      <GoogleLogin
        shape="pill"
        text="signin"
        theme="filled_black"
        size="large"
        onSuccess={(credentialResponse) => {
          const idToken = credentialResponse.credential;
          if (idToken) {
            const { name, email, picture } = jwtDecode<BasicProfile>(idToken);

            setIdToken(idToken);
            setBasicProfile({ name, email, picture });
            localStorage.setItem('token', idToken);
          }
        }}
      />
    </div>
  );
};

export const SignOutButton = () => {
  const signOut = useSignOut();

  return (
    <Tooltip content="Sign out" placement="right">
      <Button onClick={signOut} mode="rock" icon={<ExitIcon />} />
    </Tooltip>
  );
};
