import React, {CSSProperties, useEffect, useState} from "react";
import {useAuthentication} from "../auth/state";
import {imageDownloadUrl} from "../../misc";

interface OwnProps {
    className?: string,
    objectKey: string,
    alt?: string,
    style?: CSSProperties | undefined,
    width?: string | number | undefined,
    height?: string | number | undefined
}

const S3Image = ({className, objectKey, alt = "", ...rest}: OwnProps) => {
    const {idToken: token} = useAuthentication();
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!objectKey) return;
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);

        fetch(`${imageDownloadUrl}/${objectKey}`, {headers})
            .then(response => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.blob();
            })
            .then(blob => {
                setImageUrl(URL.createObjectURL(blob));
            })
            .catch(error => {
                console.error("There was a problem:", error);
            });
    }, [objectKey, token]);

    return (
        <img
            className={className}
            alt={alt}
            src={imageUrl}
            {...rest}
        />);
};

export default S3Image;
